.celebration{
    /* position: absolute; */
    margin-top:0;
    margin-right:auto;
    margin-bottom:0;
    margin-left:auto;
    height: 500px;
    width: 500px;
    z-index: 99;

     -moz-animation: cssAnimation 0s ease-in 6s forwards;
    /* Firefox */
    -webkit-animation: cssAnimation 0s ease-in 6s forwards;
    /* Safari and Chrome */
    -o-animation: cssAnimation 0s ease-in 6s forwards;
    /* Opera */
    animation: cssAnimation 0s ease-in 6s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes cssAnimation {
    to {
        width:0;
        height:0;
        overflow:hidden;
    }
}

@-webkit-keyframes cssAnimation {
    to {
        width:0;
        height:0;
        visibility:hidden;
    }
}