.pointbox {
    display: inline-block;
    margin: 2%;
    background-color:white;
    text-align: center;
    width: 200px;
}

.avatar {
    width: 45px;
    height: 45px;
    margin-top: -30px;
    margin-left: -15px;
    border-radius: 50% !important;
}

.card-name{
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: block;
    line-height: 1em; /* a */
    max-height: 2em; /* a x number of line to show (ex : 2 line)  */
}

.card-message-img-think {
    background-image: url("emoji/thinking_face.gif");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.card-message-img-dizzy {
    background-image: url("emoji/dizzy_face.gif");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.card-message-img-sleep {
    background-image: url("emoji/sleeping_face.gif");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.timebox {
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: contain; /* Resize the background image to cover the entire container */
    background-image: url(timebox.svg);
}