@font-face {
    font-family: 'Kismet NF Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Kismet NF Regular'), url('KismetNF.woff') format('woff');
}

.logotext {
    font-family: 'Kismet NF Regular';
    font-weight: normal;
    font-size: 90px;
    text-align: center;
}

.img-biglogo {
    border: 0px;
}

.p-15vh {
    padding-top: 7.5vh;
    padding-bottom: 7.5vh;
}

.h-screen-90 {
    height: 85vh;
}

.border-bg {
    background-image: url("./img/geometry.png");
}


/* .chat-bg {background: #D9ADD1;} 
.received-message-bg {background: #A94399;}
.sent-message-bg {background: #D27AC4;} */

.signout-btn {
    top: 10px;
    right: 10px;
}

.wrapper {
    min-height: 100vh;
    background: #ccc;
    display: flex;
    flex-direction: column;
}

.header,
.footer {
    height: 50px;
    background: #666;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logoutbutton{
    margin-right: 30px;
}

.header img {
    border: 0;
    margin-left: 30px;
    display: inline-block;
    padding-right: 10px;
}

.header button{
   background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.footer button{
   background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.navText{
    font-family:'Kismet NF Regular';
    font-weight:lighter;
    font-size:30px;
    letter-spacing: 2px;
}


.content {
    display: flex;
    flex: 1;
    background: #999;
    color: #000;
}

.columns {
    display: flex;
    flex: 1;
}

.main {
    flex: 1;
    order: 2;
    background: #eee;
    background-image: url("./img/geometry.png");
}

.sidebar-first {
    width: 20%;
    background: #ccc;
    background-image: url("./img/geometry.png");
    order: 1;
}

@media screen and (max-width: 1000px) {
      .sidebar-first {
         width: 0%;
      }
    }



.sidebar-second {
    order: 3;
    width: 20%;
    background: #ddd;
    background-image: url("./img/geometry.png");
}

@media screen and (max-width: 1000px) {
      .sidebar-second {
         width: 0%;
      }
    }

.messagebody {
    font-size: 3em;
}

.loginDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.bigBreak {
  flex-basis: 100%;
  height: 1em;
}

.userlist {
    margin-top: 10px;
}

.bigLogo{
    border: 0px;
    font-family:'Kismet NF Regular';
    font-weight:normal;
    font-size:90px;
    text-align:center;
}

.medLogo{
    border: 0px;
    font-family:'Kismet NF Regular';
    font-weight:normal;
    font-size:45px;
    text-align:center;
}


.row {
    text-align:center;
      align-items: center;
  justify-content: center;
}


.fixednav {
  display: flex;
  flex-direction: row;
  align-items: center; /* aligns links vertically */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 60px;
  background-color: rgba(0, 0, 0, 1);
}

.leftnav {
  flex-grow: 1; /* this is the important bit */
}

.buttonDiv{
    padding-top: 100px;
}

.buttonDiv button {
    margin: 10px;
}

.vertical-center {
    vertical-align: text-top;
  align-items:center;
}